.<template>
  <div class="footer" :style="backgroundStyle">
    <div class="footer-content">
      <div class="intro">
        <h2 class="title">About</h2>
        <p>
          iShiner is a drug discovery platform driven by advanced deep-learning
          algorithm including ligand-based learning and molecular generation
          developed by ShenNong Intelligence Drug Team, aims to shorten the drug
          development cycle, improve the success rate of clinical translation,
          fully empower the new drug research & development projects.
        </p>
        <a class="copy-right">Copyright © iDruglab.&nbsp;All rights reserved</a>
      </div>
      <div class="links">
        <h2 class="title">Quick Links</h2>
        <p class="link-item">Service Support</p>
        <p class="link-item">Publication</p>
        <p class="link-item">Contact Us</p>
        <p class="link-item" @click="routeTo('/user')">User Center</p>
      </div>
      <div class="map">
        <a href="https://www.revolvermaps.com/livestats/50u0eazzc8b/">
          <img src="//rf.revolvermaps.com/h/m/a/0/ff0000/146/10/50u0eazzc8b.png" width="285" height="147" alt="Map"
            style="border: 0" />
        </a>
        <p class="email">Email:&nbsp;&nbsp;jasonwu717@foxmail.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
  },
  computed: {
    backgroundStyle() {
      if (this.$route.path == "/home") {
        return {
          background:
            "linear-gradient(var(--color1-light), var(--color1-dark))",
        };
      } else {
        return {
          background:"transparent"
        };
      }
    },
  },
};
</script>

<style scoped>
.footer {
  padding: 20px;
  cursor: default;
  display: flex;
  justify-content: center;
}

.footer-content {
  max-width: 80%;
  display: flex;
  color: white;
  text-align: center;
}

.intro {
  flex: 2;
  text-align: justify;
}

.links {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
}

.link-item:hover {
  color: var(--color2-dark);
}

.map {
  flex: 1;
}

.title {
  margin: 10px 0;
  font-size: 20pt;
}

.copy-right {
  display: block;
  margin: 5px 0;
}
</style>