import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../api/store';


//  要添加路由，则在routes中添加一个对象  by hailin
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/user',
    name: 'User',
    redirect: '/user/info',
    meta: {
      requireAuth: true,
    },
    component: () => import('../views/User.vue'),
    children: [
      {
        path: 'info',
        component: () => import('../components/user/profile.vue'),
      }, {
        path: 'change-pwd',
        component: () => import('../components/user/changePwd.vue'),
      }, {
        path: 'activity_history',
        component: () => import('../components/user/activity_history.vue'),
      }, {
        path: 'virtual_history',
        component: () => import('../components/user/virtual_history.vue'),
      }, {
        path: 'molecular_history',
        component: () => import('../components/user/molecular_history.vue'),
      }
    ]
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/database',
    name: 'DataBase',
    component: () => import('../views/DataBase.vue'),
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/Service.vue'),
  },
  {
    path: '/service/activity-prediction/single',
    name: 'SinglePrediction',
    component: () => import('../views/SinglePrediction.vue')
  },
  {
    path: '/service/activity-prediction/batch',
    name: 'BatchPrediction',
    component: () => import('../views/BatchPrediction.vue')
  },
  {
    path: '/service/activity-prediction/single-predict-result/:resultId',
    name: 'SinglePredictResult',
    props: true,
    component: () => import('../views/SinglePredictResult.vue')
  },
  {
    path: '/service/virtual/virtual-result/:resultId',
    name: 'VirtualResult',
    props: true,
    component: () => import('../views/VirtualResult.vue')
  },
  {
    path: '/service/virtual',
    name: 'Virtual',
    component: () => import('../views/Virtual.vue')
  },
  {
    path: '/service/MolecularGeneration',
    name: 'MolecularGeneration',
    props: true,
    component: () => import('../views/MolecularGeneration.vue')
  },

  {
    path: '/service/activity-prediction/multi-predict-result/:resultId',
    name: 'MultiPredictResult',
    props: true,
    component: () => import('../views/MultiPredictResult.vue')
  },
  {
    path: '/service/predict-result-detail',
    name: 'PredictResultDetail',
    component: () => import('../views/PredictResultDetail.vue')
  },
  {
    path: '/publication',
    name: 'Publication',
    component: () => import('../views/Publication.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    children: [
      {
        path: 'sign-in',
        name: 'SignIn',
        component: () => import('../components/account/signin.vue'),
      }, {
        path: 'sign-up',
        name: 'SignUp',
        component: () => import('../components/account/signup.vue'),
      }, {
        path: 'find-pwd',
        name: 'FindPwd',
        component: () => import('../components/account/findPwd.vue'),
      }
    ]
  },


];





const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (store.state.token) {
      next();
    } else {
      next({
        path: '/account/sign-in',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next();
  }
})

export default router
