<template>
  <div id="app" :style="backgroundStyle">
    <Header></Header>
    <main id="main">
      <router-view></router-view>
    </main>
    <Footer></Footer>
    <div v-show="state.isShowMsg" :class="['msg', state.msgType]">
      <div class="msg-icon">
        <el-icon v-if="state.msgType == 'ok'" class="ok-icon">
          <circle-check-filled />
        </el-icon>
        <el-icon v-else-if="state.msgType == 'error'" class="err-icon">
          <circle-close-filled />
        </el-icon>
      </div>
      <span>{{ state.msgValue }}</span>
    </div>
    <el-backtop>
      <div class="to-top">
        <el-icon>
          <arrow-up-bold />
        </el-icon>
      </div>
    </el-backtop>
    <Loading v-show="loadingState.isLoading"></Loading>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import store from "./api/store";
import Loading from "./components/loading.vue";

export default {
  name: "App",
  data() {
    return {
      state: store.state.msgState,
      loadingState: store.state.loadingState
    };
  },
  computed: {
    backgroundStyle() {
      if (this.$route.path != "/home") {
        return {
          background:
            "linear-gradient( var(--color1-dark),var(--color1-light))",
        };
      } else {
        return {
          background: "white",
        };
      }
    },
  },
  methods: {},
  components: {
    Header,
    Footer,
    Loading,
  },
  mounted() { },
};
</script>

<style>
@import "./assets/fonts/main.css";

* {
  margin: 0;
  padding: 0;
}

#main {
  min-height: 100vh;
}

:root {
  --primary-color: rgb(4, 26, 121);
  --color1-dark: #121b59;
  /*rgba(18, 27, 89)*/
  --color1-light: #4a6aab;
  /*rgba(74, 106, 171)*/
  --color2-dark: #8bbbed;
  /*rgba(139, 187, 237)*/
  --color2-light: #e8f4ff;
  /*rgba(205, 233, 245)*/
  --menu-size: 16pt;
  /* 导航栏 */
  --h1-size: 28pt;
  /* 大标题 */
  --h2-size: 22pt;
  /* 小标题 */
  --p-size: 14px;
  /* 正文大小 */
  --p-height: 18pt;
  /* 正文行高 */
}

#app {
  font-family: FZYouHJW;
  background-size: contain;
  /* position: relative; */
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  /* background: var(--color2-dark); */
  color: black;
}

.to-top {
  color: var(--color1-dark);
  display: flex;
  justify-content: center;
  align-content: center;
}

.msg {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  width: fit-content;
  color: var(--color1-dark);
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 1px;
  border-radius: 10px;
  animation: showmsg 500ms ease;
  z-index: 9999;
  background-color: var(--color2-light);
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 0px 2px 2px #8bbbed;
}

.el-icon {
  font-size: 20pt;
  margin: 0 10px;
}

.el-icon.ok-icon {
  color: green;
}

.el-icon.err-icon {
  color: red;
}

@keyframes showmsg {
  from {
    opacity: 0;
    top: 20px;
  }

  to {
    opacity: 1;
    top: 80px;
  }
}
</style>
