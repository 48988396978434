<template>
  <div class="home">
    <div class="introduction">
      <div class="text">
        <h1 class="title">What is iShiner?</h1>
        <img src="../assets/homepage/decoration1.png" />
        <p class="main">
          A drug discovery platform driven by advanced deep-learning algorithm
          including ligand-based learning and molecular generation developed by
          ShenNong Intelligence Drug Team, aims to shorten the drug development
          cycle, improve the success rate of clinical translation, fully empower
          the new drug research and development projects.
        </p>
      </div>
    </div>
    <div class="rowcenter">
      <div style="width: 1082px">
        <h2 class="subtitle">Deep Learning Algorithm</h2>
        <div class="rowcenter">
          <img
            style="width: 270pt; text-align: center; margin-top: 5px"
            src="../assets/homepage/decoration2.png"
          />
        </div>
        <p class="algorithmintro">
          We advanced a novel deep learning algorithm named FP-GNN which study
          and integrate the information from molecular graph and molecular
          fingerprint, especially suitable for the fluctuant data challenges.
          Comparison performance test with many sophisticated machine learning
          algorithms on 13 classical benchmark public datasets and an unbiased
          dataset LIT-PCBA indicates FP-GNN can achieve state-of-the-art
          performance.
        </p>
        <img
          style="margin-top: 42px; width: 100%"
          src="../assets/homepage/photo1.png"
        />
      </div>
    </div>
    <div class="rowcenter">
      <div style="width: 1082px">
        <h2 class="subtitle">Data Advantages</h2>
        <div class="rowcenter">
          <img
            style="width: 270pt; text-align: center; margin-top: 5.5px"
            src="../assets/homepage/decoration2.png"
          />
        </div>
        <p class="algorithmintro">
          The iShiner platform integrates 1,434 models trained to fit 4,287,130
          ligand molecule structure information and their bioactivity data,
          including cancer cell-lines, anti-cancer targets, human kinases, NCI
          cancer cell-lines, virus cell models and virus target. The a rich
          diversity molecular scaffold proportion is contained in the dataset
          and the disease categories hierarchies can beneficially meet the
          clinical research needs.
        </p>
        <img
          style="margin-top: 42px; width: 100%"
          src="../assets/homepage/photo2.png"
        />
      </div>
    </div>
    <div class="rowcenter">
      <div style="width: 1082px">
        <h2 class="subtitle">Functions</h2>
        <div class="rowcenter">
          <img
            style="width: 270pt; text-align: center; margin-top: 5.5px"
            src="../assets/homepage/decoration2.png"
          />
        </div>
        <div class="functionintros">
          <div class="functionlogo">
            <img src="../assets/homepage/logo1.png" style="width: 80%" />
          </div>
          <div class="functiontext">
            <h2 class="functiontitle">Activity Predicting</h2>
            <p class="functionintro">
              This sevice works with deep-learning models trained by FP-GNN
              algorithm on the small molecule. Bioactivity Evaluation module is
              composed of a series of machine learning-based models which can
              predict the activity of the molecule against cancer cell lines,
              anti-cancer targets, human kinases, virus cell-line models, or
              virus targets, helping users to judge potential bioactivity and
              conduct further test, as performing target fishing in laboratory.
            </p>
          </div>
        </div>
        <div class="functionintros">
          <div class="functionlogo">
            <img src="../assets/homepage/logo2.png" style="width: 80%" />
          </div>
          <div class="functiontext">
            <h2 class="functiontitle">Virtual Screening</h2>
            <p class="functionintro">
              Artificial Intelligence could learn the relationship between
              molecule structure and bioactivity with target protein, and the
              logical relation learned could be used to mine potential active
              compound in the commercial compound databases or dataset submitted
              with high-throughput, suitable for unclear information with 3D
              target protein structure. iShiner support 1434 target trained on
              4,287,130 data points collected and standardized from several
              authoritative databaset.
            </p>
          </div>
        </div>
        <div class="functionintros">
          <div class="functionlogo">
            <img src="../assets/homepage/logo3.png" style="width: 80%" />
          </div>
          <div class="functiontext">
            <h2 class="functiontitle">Molecular Generation</h2>
            <p class="functionintro">
              In the context of the intense consumption of compound space by
              high-throughput experiments and virtual screening, the known
              compound space is no longer able to meet the needs of new drug
              development. An AI-based molecule generation algorithm is offered
              on iShiner to generate potentially active small molecules when
              only the target protein is provided, or to generate molecules for
              a dominant scafold provided by the user.
            </p>
          </div>
        </div>
        <div class="functionintros">
          <div class="functionlogo">
            <img src="../assets/homepage/logo4.png" style="width: 80%" />
          </div>
          <div class="functiontext">
            <h2 class="functiontitle">Modeling</h2>
            <p class="functionintro">
              To fit the difficulty with small dataset inevitably faced by AI
              drug discovery industry, a deep learning algorithm developed by us
              named FP-GNN combining shallow fingerprint features and deep graph
              structure features to better learn the conformational
              relationships between small molecule structures and their targets
              of action. You can submit your own training dataset and choose the
              necessary parameters to construct your self-defined models with
              FP-GNN.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Carousel from "../components/carousel.vue";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    // Carousel,
  },
};
</script>

<style scoped>
.home {
  text-align: center;
  padding-bottom: 50px;
  font-family: FZYouHJW;
}

.introduction {
  display: flex;
  flex-direction: row;
  height: 680px;
  justify-content: center;
  background-image: url("../assets/homepage/background.png");
  background-size: cover;
}
.rowcenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-bottom-intro {
  height: 100%;
  font-weight: 500;
  text-align: left;
}

.page-bottom-title {
  font-size: 20pt;
  font-weight: 900;
  color: white;
}

.email {
  font-size: 14pt;
  color: white;
  margin-top: 21px;
}

.page-bottom-font {
  font-size: 14pt;
  color: white;
  line-height: 17.4pt;
}

.nonewline {
  white-space: nowrap;
}

.text {
  width: 1082px;
  text-align: left;
  margin-top: 212px;
}

.text img {
  width: 240pt;
  margin: 0px -25pt;
  margin-top: -30px;
}

.title {
  font-size: 32pt;
  color: white;
}

.columnstart {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.subtitle {
  font-size: 25pt;
  margin-top: 42px;
}

.functionlogo {
  height: 100%;
  margin-left: 5%;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-bottom {
  background: var(--color2-light);
  margin-top: 146px;
  height: 320px;
  width: 100%;
}

.map {
  height: 100%;
}

.main {
  width: 50%;
  font-size: 14pt;
  color: white;
  margin: 41px 0px;
  line-height: 17.4pt;
  letter-spacing: 0px;
  text-align: justify;
  word-break: break-word;
}

.algorithmintro {
  width: 100%;
  font-size: 14pt;
  margin-top: 42px;
  line-height: 17.4pt;
  letter-spacing: 0px;
  text-align: justify;
  /* text-align: left; */
  word-break: break-word;
}

.functionintros {
  width: 100%;
  margin-top: 50px;
  height: 304.5px;
  background: linear-gradient(
    to bottom,
    var(--color2-light),
    var(--color2-dark)
  );
  display: flex;
  border-radius: 10px;
  flex-direction: row;
}

.functionintro {
  width: 95%;
  font-size: 14pt;
  margin-top: 15.6px;
  line-height: 17.4pt;
  letter-spacing: 0px;
  text-align: justify;
  word-break: break-word;
}

.functiontext {
  width: 75%;
  margin-left: 5%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.columncenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.functiontitle {
  font-size: 20pt;
}
</style>
